<template>
	<div>
		<a-select v-model="template" :options="templates" />
	</div>
</template>

<script>
export default {
	props: {
		value: {
			type: String,
			required: true,
			default: ''
		},
		instance: {
			type: String,
			required: true,
		},
	},
	data() {
		return {
			template: '',
			templates: [],
		}
	},
	computed: {

	},
	watch: {
		template: {
			handler(val) {
				return this.$emit('input', val)
			}
		}
	},

	methods: {
		fetchEmailTemplates() {
			this.$api.get(`/projects/${this.instance}?type=email&default=true`).then(({ data }) => {
				this.templates = data.map(({ id, name }) => {
					return {
						label: name,
						value: id
					}
				});
				if (!this.value && this.templates && this.templates.length) {
					return this.$emit('input', this.templates[0].value)
				}
			}).catch((error) => {
				console.log('Error while fetching email templates', error)
			})
		}
	},
	mounted() {

	},
	created() {
		this.fetchEmailTemplates();
		this.template = this.value
	},
}
</script>

<style scoped lang="scss">

</style>
